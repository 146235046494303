document.querySelectorAll('.category_content_link').forEach(el => {
    el.addEventListener('click', () => {
        fetch(`${el.href}?sub_category=${el.dataset.slug}`)
            .then(response => response.json())
    })
})

document.querySelectorAll('.item_like > img').forEach(item => {
    item.addEventListener('click', (e) => {
        let productId = {
            item_id: item.closest('[data-id]').dataset.id
        }
        if (item.classList.contains('liked')) {
            fetch(`/api/favour_item/${productId.item_id}/remove_by_like`, {
                method: 'DELETE',
            })
                .then(response => {
                    response.json()
                    if (response.ok) {
                        item.classList.remove('liked')
                    }
                })

            e.preventDefault()
        }
        else {
            fetch('/api/favour_items/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Accept": "application/json"
                },
                body: JSON.stringify(productId)
            })
                .then(response => {
                    response.json()
                    if (response.ok) {
                        item.classList.add('liked')
                    }
                })

            e.preventDefault()
        }
    })
})