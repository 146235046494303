import './index.scss';
import './search/index.scss';
import "./search/index";

window.onload = function () {


    {
        let navigation_phone_arrow = document.querySelectorAll('.navigation_phone_arrow');
        navigation_phone_arrow.forEach(phone_element => {
            phone_element.addEventListener("click", function () {
                let navigation_another_phone = phone_element.closest('.header_phone__block').querySelector('.header_another_phone');
                navigation_another_phone.classList.toggle('active');
            });
        });



    }

    {
        let menu_btn = document.getElementsByClassName('header_hamburger')[0];
        menu_btn.addEventListener("click", menu_action);

        function menu_action() {
            let header_navigation = document.getElementsByClassName('header')[0];
            header_navigation.classList.toggle('active');
        }
    };

    let window_width = window.innerWidth;
    let mobile_link = document.querySelectorAll('.header_nav_content__block');
    if (window_width <= 992) {
        mobile_link.forEach(link => {
            let disable_link = link.querySelector('.header_nav_link');
            disable_link.addEventListener("click", function (e) {
                e.preventDefault();
            })
        });
    }


}

document.querySelectorAll('.header_nav_content_link').forEach(el => {
    el.addEventListener('click', (e) => {
        e.preventDefault();
        window.location = `${el.href}?sub_category=${el.dataset.slug}`
    })
})

document.querySelectorAll('.navigation_icon_like').forEach(el => {
    el.addEventListener('click', (e) => {
        //document.querySelector('.login__btn').dataset.section = el.dataset.section
        if (el.href) {
            e.preventDefault()
            window.location = `${el.href}?section=${el.dataset.section}`
        }
    })
})

document.querySelectorAll('.navigation_icon_profile').forEach(el => {
    el.addEventListener('click', (e) => {
        //document.querySelector('.login__btn').dataset.section = el.dataset.section
        if (el.href) {
            e.preventDefault()
            window.location = `${el.href}?section=${el.dataset.section}`
        }
    })
})

document.querySelectorAll('.product_card_color').forEach(el => {
    el.addEventListener('click', () => {
        window.location = el.attributes.href.value
    })
})

document.querySelectorAll('.header_nav_content__block').forEach(item => {
    item.addEventListener('click', () => {
        item.classList.toggle('active')
    })
})
